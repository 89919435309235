.background {
  background: center/100%
    url("../../public/1920px-Cerros_Pintados\,_Pampa_del_Tamarugal\,_Chile\,_2016-02-11\,_DD_121.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.login-card {
  width: 100%;
  height: 100%;
}
@media (min-width: 810px) {
  .login-card {
    width: 60%;
    height: 90%;
  }
}
@media (min-width: 1440px) {
  .login-card {
    width: 30%;
    height: 90%;
  }
}

#floatingRut {
  width: 100%;
}
