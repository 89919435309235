#modalWindow {
  max-width: 1340px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

#modalWindowDocente {
  max-width: 700px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
#modalWindowDocente {
  max-width: 700px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
input[type="checkbox"] {
  vertical-align: middle;
}

.span12.pagination-centered {
  margin: 50px auto;
  width: 100px;
}

.container-body {
  border-radius: 3%;
  margin-top: 30px;
  border-style: solid;
  background-color: rgb(231, 237, 237);
  padding: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.62);
}

#userData {
  width: 60%;
  margin: 0 auto;
}

#body-add-alumno {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
