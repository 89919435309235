.tb-container {
  overflow: hidden;
  border-radius: 6px;
}

.search-container {
  margin-bottom: 10px;
}

.search-input {
  padding: 8px;
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.search-input:focus {
  outline: none;
  border: 1px solid #007bff;
}

.button .icon {
  height: 20px;
}
