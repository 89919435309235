.placeholder-img {
  border-radius: 10%;
  border-color: black;
  overflow: hidden;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.62);
}

.placeholder-img img {
  max-width: 1200px;
  max-height: 35vh;
  object-fit: contain;
}
